import Home from '/src/views/Home.vue';
import Profil from '/src/views/Profil.vue';
import App from '/src/views/App.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Beranda',
    },
  },
  {
    path: '/profil',
    name: 'profil',
    component: Profil,
    meta: {
      title: 'Profil',
    },
  },
  {
    path: '/app',
    name: 'app',
    component: App,
    meta: {
      title: 'Layanan',
    },
  },
];
