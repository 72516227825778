<template>
  <button
    type="button"
    class="fixed z-50 bottom-4 right-4 w-16 h-16 rounded-full bg-gray-900 text-white block lg:hidden"
  >
    <span class="sr-only">Open site navigation</span>
    <MenuIcon
      v-if="open"
      class="h-6 w-6 absolute top-1/2 left-1/2 -mt-3 -ml-3 transition duration-300 transform"
      @click="toggleMenu(false)"
    />
    <XIcon
      v-else
      class="h-6 w-6 absolute top-1/2 left-1/2 -mt-3 -ml-3 transition duration-300 transform"
      @click="toggleMenu(true)"
    />
  </button>
  <div id="sidebar" :class="['sidebar', { hidden: open }]">
    <div
      id="navWrapper"
      class="
        h-full
        overflow-y-auto
        scrolling-touch
        lg:h-auto lg:block lg:relative lg:sticky lg:bg-transparent
        overflow-hidden
        lg:top-18
        bg-white
        mr-24
        lg:mr-0
      "
    >
      <div class="hidden lg:block h-12 pointer-events-none absolute inset-x-0 z-10 bg-gradient-to-b from-white"> </div>
      <nav
        id="nav"
        class="
          px-1
          pt-6
          overflow-y-auto
          font-medium
          text-base
          sm:px-3
          xl:px-5
          lg:text-sm
          pb-10
          lg:pt-10 lg:pb-14
          sticky?lg:h-(screen-18)
        "
      >
        <ul>
          <li>
            <h5 class="px-3 mb-3 lg:mb-3 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900">
              Menu
            </h5>
            <ul>
              <li v-for="(menu, idx) in menus" :key="idx">
                <template v-if="menu.to">
                  <router-link
                    active-class="active"
                    :to="menu.to"
                    class="
                      px-3
                      py-2
                      transition-colors
                      duration-200
                      relative
                      block
                      hover:bg-blue-300 hover:text-white
                      text-gray-500
                    "
                  >
                    <span class="rounded-md absolute inset-0 bg-cyan-50 opacity-0"></span>
                    <div class="flex items-center">
                      <component :is="menu.icon" class="h-4 w-4"></component>
                      <span class="relative ml-2">{{ menu.title }}</span>
                    </div>
                  </router-link>
                </template>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { XIcon, MenuIcon } from '@heroicons/vue/solid';
import menus from '/src/menus';
import { ref } from 'vue';

const open = ref(false);
const toggleMenu = (value) => {
  open.value = value;
};
</script>

<style scoped>
.sidebar {
  @apply fixed
  z-40
  inset-0
  flex-none
  h-full
  bg-black bg-opacity-25
  w-full
  lg:bg-white lg:h-auto lg:overflow-y-visible lg:pt-0 lg:w-60
  xl:w-72
  lg:mt-10
  lg:block;
}
.active {
  @apply bg-blue-300 text-white;
}
</style>
