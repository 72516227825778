import { computed, inject, provide, reactive } from 'vue';

export const initStore = () => {
  // State
  const state = reactive({
    nama: '',
    foto_url: '',
  });

  // Getters
  const getNama = computed(() => state.nama);
  const getFoto = computed(() => state.foto_url);

  // Actions
  const updateNama = (nama) => {
    state.nama = nama;
  };
  const updateFoto = (foto_url) => {
    state.foto_url = foto_url;
  };

  provide('getNama', getNama);
  provide('getFoto', getFoto);
  provide('updateNama', updateNama);
  provide('updateFoto', updateFoto);
};

export const useStore = () => {
  return {
    getNama: inject('getNama'),
    getFoto: inject('getFoto'),
    updateNama: inject('updateNama'),
    updateFoto: inject('updateFoto'),
  };
};
