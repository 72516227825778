import { gql } from '@apollo/client/core';

const TAUTAN = gql`
  query GetTautan {
    tautan {
      id
      k_jenis_sosial
      email
    }
  }
`;

const HAPUS_TAUTAN = gql`
  mutation HapusTautan($id: Int!) {
    deleteTautan(id: $id) {
      id
      k_jenis_sosial
      email
    }
  }
`;
export { TAUTAN, HAPUS_TAUTAN };
