<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed z-50 inset-0 overflow-y-auto" :open="open" @close="!open">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-xl sm:w-full
            "
          >
            <div
              class="
                cursor-pointer
                absolute
                top-0
                right-0
                mt-4
                mr-5
                text-gray-400
                hover:text-gray-600
                transition
                duration-150
                ease-in-out
              "
              @click="$emit('close')"
            >
              <XIcon class="h-5 w-5 icon icon-tabler icon-tabler-x" />
            </div>
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  v-if="options && options.tipe"
                  :class="[
                    'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    options && options.tipe === 'info'
                      ? 'bg-blue-100'
                      : options && options.tipe === 'success'
                      ? 'bg-green-100'
                      : 'bg-red-100',
                  ]"
                >
                  <ExclamationCircleIcon
                    v-if="options && options.tipe === 'info'"
                    class="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                  <CheckIcon
                    v-else-if="options && options.tipe === 'success'"
                    class="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                  <XIcon
                    v-else-if="options && options.tipe === 'error'"
                    class="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    {{ (options && options.title) || '' }}
                  </DialogTitle>
                  <div class="mt-2">
                    <slot>
                      <p class="text-sm text-gray-500">
                        {{ (options && options.desc) || '' }}
                      </p>
                    </slot>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                :class="[M_BUTTON[options && options.tipe] || 'default-button']"
                :disabled="loading"
                @click="$emit('save')"
              >
                <svg
                  v-if="loading"
                  fill="none"
                  class="w-6 h-6 animate-spin"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
                <div class="ml-1 font-normal text-white">
                  {{ loading ? 'Loading' : (options && options.labelOk) || 'Simpan' }}
                </div>
              </button>
              <button type="button" class="ouline-button" @click="$emit('close')">
                {{ (options && options.labelNok) || 'Tutup' }}
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { defineProps, ref, toRefs, defineEmits } from 'vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationCircleIcon, CheckIcon, XIcon } from '@heroicons/vue/outline';
const props = defineProps({
  open: Boolean,
  loading: Boolean,
  options: Object,
});
const M_BUTTON = ref({
  info: 'default-button',
  success: 'success-button',
  error: 'error-button',
});
const { open } = toRefs(props);
const emit = defineEmits(['close', 'save']);
</script>
<style>
.default-button {
  @apply w-full
  inline-flex
  justify-center
  rounded-md
  border border-transparent
  shadow-sm
  px-4
  py-2
  text-base
  font-medium
  text-white
  bg-blue-600
  hover:bg-blue-700
  sm:ml-3 sm:w-auto sm:text-sm;
}
.error-button {
  @apply w-full
  inline-flex
  justify-center
  rounded-md
  border border-transparent
  shadow-sm
  px-4
  py-2
  text-base
  font-medium
  text-white
  bg-red-600
  hover:bg-red-700
  sm:ml-3 sm:w-auto sm:text-sm
  focus:outline-none;
}
.success-button {
  @apply w-full
  inline-flex
  justify-center
  rounded-md
  border border-transparent
  shadow-sm
  px-4
  py-2
  text-base
  font-medium
  text-white
  bg-green-600
  hover:bg-green-700
  sm:ml-3 sm:w-auto sm:text-sm
  focus:outline-none;
}
.ouline-button {
  @apply mt-3
  w-full
  inline-flex
  justify-center
  rounded-md
  border border-gray-300
  shadow-sm
  px-4
  py-2
  bg-white
  text-base
  font-medium
  text-gray-700
  hover:bg-gray-50
  focus:outline-none
  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
}
</style>
