import { gql } from '@apollo/client/core';

const USER = gql`
  query GetUser {
    user {
      nama
      foto_url
      tmp_lahir
      tgl_lahir
      kelamin
      email
      no_hp
    }
  }
`;

const UPDATE_FOTO = gql`
  mutation UpdateFoto($file: String!) {
    updateFoto(file: $file) {
      foto_url
    }
  }
`;

const UPDATE_PROFIL = gql`
  mutation UpdateProfil($nama: String!, $kelamin: String!, $tmp_lahir: String!, $tgl_lahir: Date!) {
    updateProfil(input: { nama: $nama, kelamin: $kelamin, tmp_lahir: $tmp_lahir, tgl_lahir: $tgl_lahir }) {
      nama
      kelamin
      tmp_lahir
      tgl_lahir
    }
  }
`;

const UPDATE_KONTAK = gql`
  mutation UpdateKontak($email: String!, $no_hp: String!) {
    updateKontak(input: { email: $email, no_hp: $no_hp }) {
      email
      no_hp
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($password_lama: String!, $password: String!, $password_confirmation: String!) {
    updatePassword(
      input: { password_lama: $password_lama, password: $password, password_confirmation: $password_confirmation }
    )
  }
`;
export { USER, UPDATE_FOTO, UPDATE_PASSWORD, UPDATE_PROFIL, UPDATE_KONTAK };
