<template>
  <div class="avatar-cropper">
    <div v-if="dataUrl" :class="['avatar-cropper-overlay', { 'avatar-cropper-overlay-inline': inline }]">
      <div v-if="!inline" class="avatar-cropper-mark">
        <a class="avatar-cropper-close" :title="labels.cancel" href="javascript:;" @click="cancel">&times;</a>
      </div>
      <div class="avatar-cropper-container">
        <div class="avatar-cropper-image-container">
          <img ref="img" :src="dataUrl" alt @load.stop="createCropper" />
        </div>
        <div class="avatar-cropper-footer">
          <button class="avatar-cropper-btn" @click.stop.prevent="cancel" v-text="labels.cancel"></button>
          <button class="avatar-cropper-btn" @click.stop.prevent="submit" v-text="labels.submit"></button>
        </div>
      </div>
    </div>
    <input ref="input" :accept="mimes" class="avatar-cropper-img-input" type="file" />
  </div>
</template>

<script>
import CropperCSS from 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default {
  name: 'AvatarCropper',
  props: {
    trigger: {
      type: [String, Element],
      required: true,
    },
    uploadFormName: {
      type: String,
      default: 'file',
    },
    cropperOptions: {
      type: Object,
      default() {
        return {
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
        };
      },
    },
    outputOptions: {
      type: Object,
    },
    outputMime: {
      type: String,
      default: 'image/jpeg',
    },
    outputQuality: {
      type: Number,
      default: 0.9,
    },
    mimes: {
      type: String,
      default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
    },
    labels: {
      type: Object,
      default() {
        return {
          submit: 'Simpan',
          cancel: 'Batal',
        };
      },
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cropper: undefined,
      dataUrl: undefined,
      filename: undefined,
    };
  },
  mounted() {
    // listen for click event on trigger
    let trigger = typeof this.trigger == 'object' ? this.trigger : document.querySelector(this.trigger);

    if (!trigger) {
      this.$emit('error', 'No avatar make trigger found.', 'user');
    } else {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.$refs.input.click();
      });
    }

    // listen for input file changes
    let fileInput = this.$refs.input;
    fileInput.addEventListener('change', () => {
      if (fileInput.files != null && fileInput.files[0] != null) {
        let correctType = this.mimes.split(', ').find((m) => m === fileInput.files[0].type);
        if (!correctType) {
          this.$emit('error', 'File type not correct.', 'user');
          return;
        }
        let reader = new FileReader();
        reader.onload = (e) => {
          this.dataUrl = e.target.result;
        };

        reader.readAsDataURL(fileInput.files[0]);

        this.filename = fileInput.files[0].name || 'unknown';
        this.mimeType = this.mimeType || fileInput.files[0].type;
        this.$emit('changed', fileInput.files[0], reader);
      }
    });
  },
  methods: {
    destroy() {
      if (this.cropper) {
        this.cropper.destroy();
      }
      this.$refs.input.value = '';
      this.dataUrl = undefined;
    },
    submit() {
      let imgBase64 = this.cropper.getCroppedCanvas().toDataURL(this.outputMime);
      const uploaddata = new FormData();
      uploaddata.append(this.nama, imgBase64);

      if (!imgBase64.match(/data:image\/(gif|jpeg|png);base64,(.*)/i)) {
        this.$error('Format file yang diunggah tidak sesuai');
        return;
      }
      this.$emit('upload', imgBase64);
      this.destroy();
    },
    cancel() {
      this.$emit('cancel');
      this.destroy();
    },
    pickImage(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$refs.input.click();
    },
    createCropper() {
      this.cropper = new Cropper(this.$refs.img, this.cropperOptions);
    },
  },
};
</script>

<style scoped>
.avatar-cropper-overlay {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.avatar-cropper-overlay-inline {
  position: initial;
}

.avatar-cropper-img-input {
  display: none;
}

.avatar-cropper-close {
  float: right;
  padding: 20px;
  font-size: 3rem;
  color: #fff;
  font-weight: 400;
  text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
}

.avatar-cropper-mark {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.avatar-cropper-container {
  background: #fff;
  z-index: 999;
  box-shadow: 1px 1px 5px rgba(100, 100, 100, 0.14);
}

.avatar-cropper-image-container {
  position: relative;
  max-width: 400px;
  height: 300px;
}

.avatar-cropper-image-container img {
  max-width: 100%;
  height: 100%;
}

.avatar-cropper-footer {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-content: space-between;
}

.avatar-cropper-btn {
  width: 50%;
  padding: 15px 0;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
}
.avatar-cropper-btn:hover {
  background-color: #2aabd2;
  color: #fff;
}
</style>
