<template>
  <div class="sticky top-0 z-40 h-16 lg:z-50 w-full max-w-8xl mx-auto bg-white flex-none flex">
    <div
      class="flex-none pl-4 sm:pl-6 xl:pl-8 flex items-center border-b border-gray-200 lg:border-b-0 lg:w-60 xl:w-72"
    >
      <a class="overflow-hidden w-10 md:w-auto" href="/">
        <span class="sr-only">Kelola Akun</span>
        <svg viewBox="0 0 248 31" class="w-auto h-10">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M 108.26,6.51
           C 108.26,6.51 108.26,0.15 108.26,0.15
             108.26,0.15 110.39,0.15 110.39,0.15
             111.15,0.15 111.91,0.45 112.37,0.91
             112.83,1.51 113.13,2.12 113.13,2.88
             113.13,2.88 113.13,3.94 113.13,3.94
             113.13,4.69 112.83,5.30 112.37,5.75
             111.91,6.21 111.15,6.51 110.39,6.51
             110.39,6.51 108.26,6.51 108.26,6.51 Z
           M 109.48,1.21
           C 109.48,1.21 109.48,5.60 109.48,5.60
             109.48,5.60 110.39,5.60 110.39,5.60
             110.85,5.60 111.15,5.45 111.46,4.99
             111.76,4.69 111.91,4.39 111.91,3.94
             111.91,3.94 111.91,2.88 111.91,2.88
             111.91,2.42 111.76,1.97 111.46,1.66
             111.15,1.36 110.85,1.21 110.39,1.21
             110.39,1.21 109.48,1.21 109.48,1.21 Z
           M 100.65,3.94
           C 100.65,3.94 100.65,6.51 100.65,6.51
             100.65,6.51 99.43,6.51 99.43,6.51
             99.43,6.51 99.43,0.15 99.43,0.15
             99.43,0.15 101.56,0.15 101.56,0.15
             102.32,0.15 102.78,0.45 103.23,0.76
             103.69,1.06 103.84,1.51 103.84,2.12
             103.84,2.42 103.84,2.72 103.54,2.88
             103.39,3.18 103.23,3.33 102.78,3.48
             103.23,3.63 103.54,3.78 103.69,4.09
             103.84,4.24 103.84,4.54 103.84,4.99
             103.84,4.99 103.84,5.45 103.84,5.45
             103.84,5.60 104.00,5.75 104.00,6.05
             104.00,6.21 104.15,6.36 104.15,6.36
             104.15,6.36 104.15,6.51 104.15,6.51
             104.15,6.51 102.93,6.51 102.93,6.51
             102.78,6.36 102.78,6.21 102.63,6.05
             102.63,5.75 102.63,5.60 102.63,5.45
             102.63,5.45 102.63,4.99 102.63,4.99
             102.63,4.69 102.63,4.39 102.32,4.24
             102.17,4.09 102.02,3.94 101.71,3.94
             101.71,3.94 100.65,3.94 100.65,3.94 Z
           M 100.65,3.03
           C 100.65,3.03 101.56,3.03 101.56,3.03
             101.86,3.03 102.17,2.88 102.32,2.72
             102.47,2.57 102.63,2.42 102.63,2.12
             102.63,1.82 102.47,1.66 102.32,1.51
             102.17,1.21 101.86,1.21 101.56,1.21
             101.56,1.21 100.65,1.21 100.65,1.21
             100.65,1.21 100.65,3.03 100.65,3.03 Z
           M 93.79,5.15
           C 93.79,5.15 91.81,5.15 91.81,5.15
             91.81,5.15 91.36,6.51 91.36,6.51
             91.36,6.51 89.99,6.51 89.99,6.51
             89.99,6.51 92.12,0.15 92.12,0.15
             92.12,0.15 93.49,0.15 93.49,0.15
             93.49,0.15 95.62,6.51 95.62,6.51
             95.62,6.51 94.25,6.51 94.25,6.51
             94.25,6.51 93.79,5.15 93.79,5.15 Z
           M 92.12,4.09
           C 92.12,4.09 93.49,4.09 93.49,4.09
             93.49,4.09 92.73,1.97 92.73,1.97
             92.73,1.97 92.73,1.97 92.73,1.97
             92.73,1.97 92.12,4.09 92.12,4.09 Z
           M 86.33,3.94
           C 86.33,4.69 86.18,5.30 85.72,5.90
             85.12,6.36 84.51,6.66 83.74,6.66
             82.98,6.66 82.37,6.36 81.92,5.90
             81.31,5.30 81.16,4.69 81.16,3.94
             81.16,3.94 81.16,2.72 81.16,2.72
             81.16,1.97 81.31,1.36 81.92,0.91
             82.37,0.30 82.98,0.15 83.74,0.15
             84.51,0.15 85.12,0.30 85.72,0.91
             86.18,1.36 86.33,1.97 86.33,2.72
             86.33,2.72 86.33,3.94 86.33,3.94 Z
           M 85.12,2.72
           C 85.12,2.27 84.96,1.97 84.81,1.51
             84.51,1.21 84.20,1.06 83.74,1.06
             83.29,1.06 82.98,1.21 82.68,1.51
             82.53,1.97 82.37,2.27 82.37,2.72
             82.37,2.72 82.37,3.94 82.37,3.94
             82.37,4.39 82.53,4.84 82.68,5.15
             82.98,5.45 83.29,5.60 83.74,5.60
             84.20,5.60 84.51,5.45 84.81,5.15
             84.96,4.84 85.12,4.39 85.12,3.94
             85.12,3.94 85.12,2.72 85.12,2.72 Z
           M 72.48,6.51
           C 72.48,6.51 72.48,0.15 72.48,0.15
             72.48,0.15 74.46,0.15 74.46,0.15
             75.22,0.15 75.83,0.30 76.28,0.61
             76.59,0.91 76.89,1.36 76.89,1.97
             76.89,2.27 76.74,2.42 76.59,2.72
             76.44,2.88 76.28,3.18 75.98,3.18
             76.28,3.33 76.59,3.48 76.89,3.78
             77.05,3.94 77.20,4.24 77.20,4.69
             77.20,5.30 76.89,5.75 76.59,6.05
             76.13,6.36 75.52,6.51 74.91,6.51
             74.91,6.51 72.48,6.51 72.48,6.51 Z
           M 73.70,3.78
           C 73.70,3.78 73.70,5.60 73.70,5.60
             73.70,5.60 74.91,5.60 74.91,5.60
             75.22,5.60 75.37,5.45 75.67,5.30
             75.83,5.15 75.83,4.99 75.83,4.69
             75.83,4.39 75.83,4.09 75.67,3.94
             75.52,3.78 75.22,3.78 74.91,3.78
             74.91,3.78 73.70,3.78 73.70,3.78 Z
           M 73.70,2.88
           C 73.70,2.88 74.46,2.88 74.46,2.88
             74.91,2.88 75.07,2.72 75.37,2.57
             75.52,2.57 75.52,2.27 75.52,1.97
             75.52,1.82 75.52,1.51 75.37,1.36
             75.07,1.21 74.91,1.21 74.46,1.21
             74.46,1.21 73.70,1.21 73.70,1.21
             73.70,1.21 73.70,2.88 73.70,2.88 Z
           M 68.06,6.51
           C 68.06,6.51 66.84,6.51 66.84,6.51
             66.84,6.51 66.84,3.94 66.84,3.94
             66.84,3.94 64.26,3.94 64.26,3.94
             64.26,3.94 64.26,6.51 64.26,6.51
             64.26,6.51 62.88,6.51 62.88,6.51
             62.88,6.51 62.88,0.15 62.88,0.15
             62.88,0.15 64.26,0.15 64.26,0.15
             64.26,0.15 64.26,2.88 64.26,2.88
             64.26,2.88 66.84,2.88 66.84,2.88
             66.84,2.88 66.84,0.15 66.84,0.15
             66.84,0.15 68.06,0.15 68.06,0.15
             68.06,0.15 68.06,6.51 68.06,6.51 Z
           M 57.56,4.84
           C 57.56,4.54 57.40,4.39 57.25,4.24
             57.10,4.09 56.79,3.94 56.34,3.94
             55.58,3.63 55.12,3.33 54.66,3.03
             54.36,2.72 54.21,2.42 54.21,1.82
             54.21,1.36 54.36,0.91 54.81,0.61
             55.27,0.30 55.73,0.15 56.49,0.15
             57.10,0.15 57.71,0.30 58.16,0.61
             58.47,1.06 58.77,1.51 58.77,1.97
             58.77,1.97 58.77,2.12 58.77,2.12
             58.77,2.12 57.56,2.12 57.56,2.12
             57.56,1.82 57.40,1.51 57.25,1.36
             56.95,1.21 56.79,1.06 56.34,1.06
             56.03,1.06 55.88,1.21 55.73,1.36
             55.58,1.51 55.42,1.66 55.42,1.82
             55.42,2.12 55.58,2.27 55.73,2.42
             55.88,2.57 56.19,2.72 56.64,2.88
             57.40,3.03 57.86,3.33 58.32,3.63
             58.62,3.94 58.77,4.39 58.77,4.84
             58.77,5.45 58.62,5.75 58.16,6.05
             57.71,6.36 57.25,6.66 56.49,6.66
             55.73,6.66 55.27,6.36 54.66,6.05
             54.21,5.75 54.05,5.30 54.05,4.54
             54.05,4.54 54.05,4.54 54.05,4.54
             54.05,4.54 55.27,4.54 55.27,4.54
             55.27,4.99 55.42,5.15 55.58,5.30
             55.88,5.60 56.03,5.60 56.49,5.60
             56.79,5.60 57.10,5.60 57.25,5.45
             57.40,5.30 57.56,5.15 57.56,4.84 Z
           M 48.72,5.15
           C 48.72,5.15 46.59,5.15 46.59,5.15
             46.59,5.15 46.14,6.51 46.14,6.51
             46.14,6.51 44.92,6.51 44.92,6.51
             44.92,6.51 47.05,0.15 47.05,0.15
             47.05,0.15 48.27,0.15 48.27,0.15
             48.27,0.15 50.40,6.51 50.40,6.51
             50.40,6.51 49.03,6.51 49.03,6.51
             49.03,6.51 48.72,5.15 48.72,5.15 Z
           M 46.90,4.09
           C 46.90,4.09 48.42,4.09 48.42,4.09
             48.42,4.09 47.66,1.97 47.66,1.97
             47.66,1.97 47.66,1.97 47.66,1.97
             47.66,1.97 46.90,4.09 46.90,4.09 Z
           M 36.39,6.51
           C 36.39,6.51 36.39,0.15 36.39,0.15
             36.39,0.15 38.52,0.15 38.52,0.15
             39.28,0.15 40.05,0.45 40.50,0.91
             40.96,1.51 41.26,2.12 41.26,2.88
             41.26,2.88 41.26,3.94 41.26,3.94
             41.26,4.69 40.96,5.30 40.50,5.75
             40.05,6.21 39.28,6.51 38.52,6.51
             38.52,6.51 36.39,6.51 36.39,6.51 Z
           M 37.61,1.21
           C 37.61,1.21 37.61,5.60 37.61,5.60
             37.61,5.60 38.52,5.60 38.52,5.60
             38.98,5.60 39.28,5.45 39.59,4.99
             39.89,4.69 39.89,4.39 39.89,3.94
             39.89,3.94 39.89,2.88 39.89,2.88
             39.89,2.42 39.89,1.97 39.59,1.66
             39.28,1.36 38.98,1.21 38.52,1.21
             38.52,1.21 37.61,1.21 37.61,1.21 Z
           M 138.10,10.59
           C 138.10,10.59 142.82,10.59 142.82,10.59
             143.74,10.59 144.50,10.59 144.95,10.75
             145.41,10.75 145.87,10.90 146.17,11.20
             146.63,11.35 146.93,11.81 147.09,12.11
             147.39,12.56 147.54,13.02 147.54,13.47
             147.54,14.08 147.39,14.53 147.09,14.98
             146.78,15.44 146.33,15.89 145.87,16.04
             146.48,16.35 147.09,16.65 147.54,17.10
             147.85,17.71 148.00,18.31 148.00,18.92
             148.00,19.52 148.00,19.98 147.70,20.43
             147.39,21.04 147.09,21.34 146.63,21.64
             146.17,21.95 145.72,22.25 145.11,22.25
             144.65,22.25 143.74,22.40 142.21,22.40
             142.21,22.40 138.10,22.40 138.10,22.40
             138.10,22.40 138.10,10.59 138.10,10.59 Z
           M 140.54,12.56
           C 140.54,12.56 140.54,15.29 140.54,15.29
             140.54,15.29 142.06,15.29 142.06,15.29
             142.98,15.29 143.58,15.29 143.89,15.14
             144.19,15.14 144.50,14.98 144.80,14.83
             145.11,14.53 145.11,14.23 145.11,13.92
             145.11,13.47 145.11,13.17 144.80,13.02
             144.65,12.71 144.35,12.56 143.89,12.56
             143.74,12.56 142.98,12.56 141.91,12.56
             141.91,12.56 140.54,12.56 140.54,12.56 Z
           M 140.54,17.25
           C 140.54,17.25 140.54,20.28 140.54,20.28
             140.54,20.28 142.82,20.28 142.82,20.28
             143.58,20.28 144.19,20.28 144.35,20.28
             144.80,20.28 145.11,20.13 145.26,19.83
             145.41,19.52 145.56,19.22 145.56,18.77
             145.56,18.46 145.56,18.16 145.41,17.86
             145.11,17.71 144.95,17.41 144.65,17.41
             144.19,17.25 143.58,17.25 142.52,17.25
             142.52,17.25 140.54,17.25 140.54,17.25 Z
           M 126.23,22.40
           C 126.23,22.40 126.23,10.59 126.23,10.59
             126.23,10.59 128.66,10.59 128.66,10.59
             128.66,10.59 128.66,15.74 128.66,15.74
             128.66,15.74 133.38,10.59 133.38,10.59
             133.38,10.59 136.73,10.59 136.73,10.59
             136.73,10.59 132.16,15.14 132.16,15.14
             132.16,15.14 136.88,22.40 136.88,22.40
             136.88,22.40 133.84,22.40 133.84,22.40
             133.84,22.40 130.49,16.80 130.49,16.80
             130.49,16.80 128.66,18.77 128.66,18.77
             128.66,18.77 128.66,22.40 128.66,22.40
             128.66,22.40 126.23,22.40 126.23,22.40 Z
           M 115.11,22.40
           C 115.11,22.40 115.11,10.59 115.11,10.59
             115.11,10.59 118.92,10.59 118.92,10.59
             120.44,10.59 121.35,10.59 121.81,10.75
             122.42,10.90 123.03,11.35 123.49,11.96
             123.94,12.41 124.25,13.32 124.25,14.23
             124.25,14.98 124.09,15.59 123.79,16.04
             123.49,16.50 123.18,16.95 122.72,17.10
             122.42,17.41 121.96,17.71 121.51,17.71
             121.05,17.86 120.14,17.86 119.07,17.86
             119.07,17.86 117.55,17.86 117.55,17.86
             117.55,17.86 117.55,22.40 117.55,22.40
             117.55,22.40 115.11,22.40 115.11,22.40 Z
           M 117.55,12.56
           C 117.55,12.56 117.55,15.89 117.55,15.89
             117.55,15.89 118.77,15.89 118.77,15.89
             119.83,15.89 120.44,15.89 120.74,15.74
             121.05,15.59 121.35,15.44 121.51,15.14
             121.66,14.83 121.66,14.53 121.66,14.23
             121.66,13.77 121.66,13.47 121.35,13.17
             121.05,12.86 120.74,12.71 120.44,12.56
             120.14,12.56 119.53,12.56 118.61,12.56
             118.61,12.56 117.55,12.56 117.55,12.56 Z
           M 101.26,22.40
           C 101.26,22.40 101.26,10.59 101.26,10.59
             101.26,10.59 104.91,10.59 104.91,10.59
             104.91,10.59 107.04,18.62 107.04,18.62
             107.04,18.62 109.17,10.59 109.17,10.59
             109.17,10.59 112.67,10.59 112.67,10.59
             112.67,10.59 112.67,22.40 112.67,22.40
             112.67,22.40 110.54,22.40 110.54,22.40
             110.54,22.40 110.54,13.02 110.54,13.02
             110.54,13.02 108.11,22.40 108.11,22.40
             108.11,22.40 105.82,22.40 105.82,22.40
             105.82,22.40 103.54,13.02 103.54,13.02
             103.54,13.02 103.54,22.40 103.54,22.40
             103.54,22.40 101.26,22.40 101.26,22.40 Z
           M 96.69,22.40
           C 96.69,22.40 96.69,10.59 96.69,10.59
             96.69,10.59 98.97,10.59 98.97,10.59
             98.97,10.59 98.97,22.40 98.97,22.40
             98.97,22.40 96.69,22.40 96.69,22.40 Z
           M 85.12,18.46
           C 85.12,18.46 87.40,18.31 87.40,18.31
             87.55,19.07 87.86,19.68 88.31,19.98
             88.62,20.43 89.23,20.58 89.99,20.58
             90.75,20.58 91.36,20.43 91.66,20.13
             92.12,19.68 92.27,19.37 92.27,18.92
             92.27,18.62 92.27,18.46 92.12,18.16
             91.81,18.01 91.66,17.86 91.21,17.71
             90.90,17.56 90.29,17.41 89.23,17.25
             88.01,16.95 87.09,16.50 86.64,16.04
             85.88,15.44 85.57,14.53 85.57,13.62
             85.57,13.02 85.72,12.41 86.03,11.96
             86.33,11.50 86.79,11.05 87.55,10.75
             88.16,10.44 88.92,10.29 89.84,10.29
             91.36,10.29 92.42,10.75 93.19,11.35
             93.95,11.96 94.25,12.86 94.40,13.92
             94.40,13.92 91.97,14.08 91.97,14.08
             91.81,13.47 91.66,13.02 91.36,12.71
             90.90,12.41 90.44,12.26 89.84,12.26
             89.07,12.26 88.62,12.41 88.16,12.71
             87.86,12.86 87.86,13.17 87.86,13.47
             87.86,13.77 87.86,14.08 88.16,14.23
             88.47,14.53 89.23,14.68 90.29,14.98
             91.51,15.29 92.42,15.59 92.88,15.89
             93.49,16.19 93.95,16.50 94.25,17.10
             94.56,17.56 94.71,18.16 94.71,18.92
             94.71,19.52 94.56,20.28 94.10,20.89
             93.79,21.34 93.19,21.79 92.58,22.10
             91.81,22.40 90.90,22.55 89.99,22.55
             88.47,22.55 87.25,22.25 86.49,21.49
             85.72,20.89 85.27,19.83 85.12,18.46 Z
           M 69.13,22.40
           C 69.13,22.40 69.13,10.59 69.13,10.59
             69.13,10.59 71.41,10.59 71.41,10.59
             71.41,10.59 76.28,18.46 76.28,18.46
             76.28,18.46 76.28,10.59 76.28,10.59
             76.28,10.59 78.57,10.59 78.57,10.59
             78.57,10.59 78.57,22.40 78.57,22.40
             78.57,22.40 76.13,22.40 76.13,22.40
             76.13,22.40 71.41,14.53 71.41,14.53
             71.41,14.53 71.41,22.40 71.41,22.40
             71.41,22.40 69.13,22.40 69.13,22.40 Z
           M 57.10,10.59
           C 57.10,10.59 59.53,10.59 59.53,10.59
             59.53,10.59 59.53,16.95 59.53,16.95
             59.53,18.01 59.53,18.62 59.69,18.92
             59.69,19.37 59.99,19.83 60.45,20.13
             60.75,20.43 61.21,20.58 61.97,20.58
             62.58,20.58 63.19,20.43 63.49,20.13
             63.80,19.83 63.95,19.52 64.10,19.07
             64.10,18.62 64.26,18.01 64.26,17.10
             64.26,17.10 64.26,10.59 64.26,10.59
             64.26,10.59 66.54,10.59 66.54,10.59
             66.54,10.59 66.54,16.80 66.54,16.80
             66.54,18.16 66.54,19.22 66.39,19.83
             66.23,20.28 66.08,20.89 65.63,21.19
             65.32,21.64 64.86,21.95 64.26,22.25
             63.65,22.40 62.88,22.55 61.97,22.55
             60.91,22.55 59.99,22.40 59.38,22.10
             58.93,21.95 58.47,21.64 58.01,21.19
             57.71,20.74 57.56,20.28 57.40,19.83
             57.25,19.22 57.10,18.16 57.10,16.80
             57.10,16.80 57.10,10.59 57.10,10.59 Z
           M 45.22,22.40
           C 45.22,22.40 45.22,10.59 45.22,10.59
             45.22,10.59 47.66,10.59 47.66,10.59
             47.66,10.59 47.66,15.74 47.66,15.74
             47.66,15.74 52.53,10.59 52.53,10.59
             52.53,10.59 55.73,10.59 55.73,10.59
             55.73,10.59 51.16,15.14 51.16,15.14
             51.16,15.14 55.88,22.40 55.88,22.40
             55.88,22.40 52.84,22.40 52.84,22.40
             52.84,22.40 49.64,16.80 49.64,16.80
             49.64,16.80 47.66,18.77 47.66,18.77
             47.66,18.77 47.66,22.40 47.66,22.40
             47.66,22.40 45.22,22.40 45.22,22.40 Z
           M 43.85,22.40
           C 43.85,22.40 41.42,22.40 41.42,22.40
             41.42,22.40 40.35,19.68 40.35,19.68
             40.35,19.68 35.63,19.68 35.63,19.68
             35.63,19.68 34.56,22.40 34.56,22.40
             34.56,22.40 31.98,22.40 31.98,22.40
             31.98,22.40 36.70,10.59 36.70,10.59
             36.70,10.59 39.13,10.59 39.13,10.59
             39.13,10.59 43.85,22.40 43.85,22.40 Z
           M 39.59,17.71
           C 39.59,17.71 37.91,13.32 37.91,13.32
             37.91,13.32 36.24,17.71 36.24,17.71
             36.24,17.71 39.59,17.71 39.59,17.71 Z
           M 8.07,11.96
           C 8.07,11.96 8.07,11.96 8.07,11.96
             5.79,11.96 3.81,12.86 2.44,14.38
             2.44,14.38 2.44,14.38 2.44,14.38
             0.91,15.74 0.00,17.86 0.00,19.98
             0.00,19.98 0.00,19.98 0.00,19.98
             0.00,22.25 0.91,24.22 2.44,25.73
             2.44,25.73 2.44,25.73 2.44,25.73
             3.81,27.09 5.79,28.00 8.07,28.00
             8.07,28.00 8.07,28.00 8.07,28.00
             10.35,28.00 12.33,27.09 13.70,25.73
             15.23,24.22 16.14,22.25 16.14,19.98
             16.14,19.98 16.14,19.98 16.14,19.98
             16.14,17.86 15.23,15.74 13.70,14.38
             13.70,14.38 13.70,14.38 13.70,14.38
             12.33,12.86 10.35,11.96 8.07,11.96 Z
           M 8.53,15.29
           C 8.53,15.29 12.94,19.52 12.94,19.52
             13.09,19.83 13.09,20.28 12.94,20.43
             12.94,20.43 8.53,24.82 8.53,24.82
             8.22,25.12 7.92,25.12 7.61,24.82
             7.61,24.82 3.20,20.43 3.20,20.43
             3.05,20.28 3.05,19.83 3.20,19.52
             3.20,19.52 7.61,15.29 7.61,15.29
             7.92,14.98 8.22,14.98 8.53,15.29 Z
           M 7.92,0.00
           C 7.92,0.00 16.14,0.00 16.14,0.00
             16.14,0.00 16.14,10.29 16.14,10.29
             16.14,10.29 8.07,10.29 8.07,10.29
             8.07,10.29 8.07,10.29 8.07,10.29
             8.07,10.29 5.79,10.29 5.79,10.29
             5.79,10.29 5.79,1.97 5.79,1.97
             5.79,0.91 6.70,0.00 7.92,0.00 Z
           M 17.97,0.00
           C 17.97,0.00 26.19,0.00 26.19,0.00
             27.26,0.00 28.17,0.91 28.17,1.97
             28.17,1.97 28.17,10.29 28.17,10.29
             28.17,10.29 17.97,10.29 17.97,10.29
             17.97,10.29 17.97,0.00 17.97,0.00 Z
           M 28.17,11.96
           C 28.17,11.96 28.17,20.13 28.17,20.13
             28.17,21.34 27.26,22.25 26.19,22.25
             26.19,22.25 17.97,22.25 17.97,22.25
             17.97,22.25 17.97,19.98 17.97,19.98
             17.97,19.98 17.97,19.98 17.97,19.98
             17.97,19.98 17.97,11.96 17.97,11.96
             17.97,11.96 28.17,11.96 28.17,11.96 Z
           M 13.70,11.96
           C 13.70,11.96 16.14,11.96 16.14,11.96
             16.14,11.96 16.14,14.38 16.14,14.38
             15.84,13.92 15.38,13.47 15.07,13.17
             15.07,13.17 15.07,13.17 15.07,13.17
             14.62,12.71 14.16,12.26 13.70,11.96 Z"
            fill="#0092DE"
          ></path>
        </svg>
      </a>
    </div>
    <div
      class="flex-auto border-b border-gray-200 h-18 flex items-center px-4 sm:px-6 lg:mx-6 lg:px-0 xl:mx-8 justify-end"
    >
      <!--layanan-->
      <Menu as="div" class="relative inline-block text-left border-r mr-4">
        <div>
          <MenuButton
            class="
              mx-2
              my-2
              flex
              items-center
              bg-white
              transition
              duration-150
              ease-in-out
              hover:border-indigo-600
              border border-indigo-700
              rounded
              text-indigo-700
              hover:text-indigo-600
              pl-3
              pr-3
              py-2
              text-sm
            "
          >
            <ViewGridIcon class="h-4 w-4" />
            <h1 class="text-gray-800 text-sm mx-3 title uppercase">Menuju Program</h1>
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="
              origin-top-right
              absolute
              right-0
              mt-2
              w-72
              rounded-md
              shadow-lg
              bg-white
              ring-1 ring-black ring-opacity-5
              divide-y divide-gray-100
              focus:outline-none
            "
          >
            <div class="py-1">
              <template v-for="(item, idx) in layanan" :key="idx">
                <MenuItem v-slot="{ active }">
                  <a
                    href="javascript:"
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                    @click="linkTo(item.url)"
                  >
                    <div class="flex items-center">
                      <div
                        class="
                          w-6
                          h-6
                          sm:mb-0
                          mb-4
                          inline-flex
                          items-center
                          justify-center
                          rounded-full
                          bg-indigo-100
                          text-indigo-500
                          flex-shrink-0
                        "
                      >
                        <img src="/src/assets/img/logo-dinas.png" />
                      </div>
                      <span class="text-sm ml-2">{{ item.nama }}</span>
                    </div>
                  </a>
                </MenuItem>
              </template>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <!--user-->
      <Menu as="div" class="relative lg:inline-block text-left">
        <div>
          <MenuButton class="flex items-center relative cursor-pointer">
            <div class="rounded-full">
              <div class="relative">
                <img
                  class="rounded-full h-10 w-10 object-cover"
                  :src="
                    fotoUrl || (user && user.foto_url) || 'https://img.icons8.com/cotton/2x/gender-neutral-user--v2.png'
                  "
                  alt="avatar"
                />
                <div
                  class="w-2 h-2 rounded-full bg-green-400 border border-white absolute inset-0 mb-0 mr-0 m-auto"
                ></div>
              </div>
            </div>
            <p class="hidden lg:block text-gray-800 text-sm mx-3">{{ nama || (user && user.nama) || '-' }}</p>
            <ChevronDownIcon class="hidden lg:block -mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="
              origin-top-right
              absolute
              right-0
              mt-2
              w-56
              rounded-md
              shadow-lg
              bg-white
              ring-1 ring-black ring-opacity-5
              divide-y divide-gray-100
              focus:outline-none
            "
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  href="javascript:"
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                  @click="onLogout"
                >
                  <div class="flex items-center">
                    <LogoutIcon class="h-5 w-5 icon icon-tabler icon-tabler-logout" />
                    <span class="text-sm ml-2">Keluar</span>
                  </div>
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
  <!-- Navigation ends -->
</template>
<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon, MenuIcon } from '@heroicons/vue/solid';
import { LogoutIcon, ViewGridIcon } from '@heroicons/vue/outline';
import { computed, defineProps } from 'vue';
import { useStore } from '../../store';
defineProps({
  user: Object,
  layanan: Array,
});

const $state = useStore();
const nama = $state.getNama;
const fotoUrl = $state.getFoto;

const dropdownHandler = (e) => {
  let single = e.currentTarget.getElementsByTagName('ul')[0];
  single.classList.toggle('hidden');
};

const onLogout = () => {
  window.location = (import.meta.env.VITE_PROXY_API || '') + '/auth/logout';
};

const linkTo = (url) => {
  window.open(url);
};
</script>

<style scoped></style>
