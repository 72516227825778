<template>
  <div class="container mx-auto flex px-5 py-1 items-center justify-center flex-col">
    <img
      class="rounded-full h-40 w-40 object-cover"
      :src="(user && user.foto_url) || 'https://img.icons8.com/cotton/2x/gender-neutral-user--v2.png'"
      alt="avatar"
    />
    <div class="text-center lg:w-2/3 w-full">
      <h1 class="title-font sm:text-4xl text-3xl mb-1 font-medium text-gray-900">
        Selamat Datang, <b>{{ (user && user.nama) || '-' }}</b>
      </h1>
      <p class="mb-8 leading-relaxed text-blue-600"> Kelola informasi pribadi akun Anda untuk SIMPKB </p>
    </div>
  </div>
  <div class="container px-5 mx-auto flex flex-wrap">
    <div class="flex flex-wrap -m-4">
      <template v-for="(menu, idx) in menus">
        <div v-if="!menu.hideOnHome" :key="idx" class="p-4 lg:w-1/2 w-full">
          <div class="flex sm:flex-row flex-col border-2 rounded-lg border-gray-200 border-opacity-50 p-4">
            <div
              class="
                mx-auto
                w-16
                h-16
                sm:mr-8 sm:mb-0
                mb-4
                inline-flex
                items-center
                justify-center
                rounded-full
                bg-indigo-100
                text-indigo-500
                flex-shrink-0
              "
            >
              <component :is="menu.icon" class="w-10 h-10"></component>
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">{{ menu.title }}</h2>
              <p class="leading-relaxed text-base pb-4">{{ menu.desc }}</p>
              <button
                class="
                  float-right
                  text-white
                  bg-indigo-500
                  border-0
                  py-2
                  px-6
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                "
                @click="linkTo(menu)"
              >
                Selengkapnya
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import apolloClient from '/src/apollo';
import { USER } from '/src/graphql/user';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { inject } from 'vue';
const $preferensi = inject('preferensi');
import menus from '/src/menus';

const router = useRouter();
const linkTo = (data) => {
  if (data && data.to) router.push(data.to);
  else if (data && data.href) {
    switch (data && data.href) {
      case '#PREFERENSI_URL_SIMPKB#':
        window.location = $preferensi.value && $preferensi.value.url_simpkb;
        break;
      default:
        window.location = data.href;
    }
  }
};

let user = ref({});
const getUser = async () => {
  const { data } = await apolloClient.query({
    query: USER,
  });
  user.value = data.user;
};
onMounted(getUser);
</script>
<style scoped></style>
