<template>
  <div class="container px-5 py-1 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-1 text-gray-900"> Integrasi Layanan </h1>
      <p class="leading-relaxed text-blue-600"> Kelola data Integrasi layanan SIMPKB Anda</p>
    </div>
    <div class="lg:w-2/3 md:w-2/3 mx-auto pb-4">
      <div class="p-4 h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-sm">
        <h1 class="text-gray-500 text-xl title-font">Integrasi Layanan</h1>
        <p class="leading-relaxed mb-4 text-gray-500 text-sm"> Integrasi Layanan yang tersedia </p>
        <div
          v-for="(layanan, kLayanan) in M_LAYANAN"
          :key="kLayanan"
          class="border-gray-200 py-2 text-xs"
        >
          <div class="flex-col">
            <div class="flex flex-col md:flex-row">
              <div class="flex">
                <div
                  class="
                    w-6
                    h-6
                    sm:mb-0
                    mb-4
                    inline-flex
                    items-center
                    justify-center
                    rounded-full
                    bg-indigo-100
                    text-indigo-500
                    flex-shrink-0
                  "
                >
                  <PuzzleIcon class="h-4 w-4" />
                </div>
                <span class="ml-2 text-blue-600 font-bold uppercase">{{ layanan }}</span>
              </div>
              <div class="lg:ml-auto flex flex-col md:flex-row">
                <template v-if="!isMaintenance">
                  <span v-if="tautan.length" class="text-left text-gray-500 m-1 mr-3">
                    {{
                      (tautan.length &&
                        getDetail({ data: tautan, id: kLayanan }) &&
                        getDetail({ data: tautan, id: kLayanan })['email']) ||
                      '-'
                    }}
                  </span>
                  <div
                    :class="[
                      tautan.length &&
                      getDetail({ data: tautan, id: kLayanan }) &&
                      getDetail({ data: tautan, id: kLayanan })['id']
                        ? 'success-chips'
                        : 'error-chips',
                    ]"
                    style="width: fit-content"
                  >
                    <div class="text-xs font-normal leading-none font-bold uppercase">
                      {{
                        tautan.length &&
                        getDetail({ data: tautan, id: kLayanan }) &&
                        getDetail({ data: tautan, id: kLayanan })['id']
                          ? 'Tertaut'
                          : 'Tidak Tertaut'
                      }}
                    </div>
                  </div>
                  <template v-if="$preferensi.acl.edit_tautan">
                    <div
                      v-if="
                        tautan.length &&
                        getDetail({ data: tautan, id: kLayanan }) &&
                        getDetail({ data: tautan, id: kLayanan })['id']
                      "
                      class="
                        ml-auto
                        flex
                        justify-center
                        items-center
                        m-1
                        font-medium
                        py-1
                        px-2
                        bg-red-500
                        cursor-pointer
                      "
                      @click="onUnlink(getDetail({ data: tautan, id: kLayanan })['id'])"
                    >
                      <XIcon class="h-4 w-4 text-white" />
                      <div class="ml-1 text-xs font-normal text-white uppercase"> Hapus</div>
                    </div>
                    <div
                      v-else
                      class="
                        ml-auto
                        flex
                        justify-center
                        items-center
                        m-1
                        font-medium
                        py-1
                        px-2
                        bg-green-500
                        cursor-pointer
                      "
                      @click="onLink(kLayanan)"
                    >
                      <LinkIcon class="h-4 w-4 text-white" />
                      <div class="ml-1 text-xs font-normal text-white uppercase"> Tautkan</div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
            <template v-if="isMaintenance">
              <i class="text-red-400">
                Maaf, untuk sementara proses pentautan akun <b class="text-blue-600">{{ layanan }}</b> belum tersedia.
                Mohon dicek secara berkala. Mohon maaf atas ketidanyamanannya
              </i>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="open" :loading="loading" :options="options" @close="onClose" @save="onConfirm"></Modal>
</template>

<script setup>
import apolloClient from '/src/apollo';
import Modal from '/src/components/Modal.vue';
import { TAUTAN, HAPUS_TAUTAN } from '/src/graphql/tautan';
import { inject, onMounted, ref } from 'vue';
import { XIcon, LinkIcon, PuzzleIcon } from '@heroicons/vue/outline';
import { $success, $error } from '/src/utils/utitity';
import { getUrlParams } from '../utils/format';

const isMaintenance = ref(false);
const tautan = ref({});
const M_LAYANAN = ref({
  3: 'Belajar.id',
});

const getTautan = async () => {
  const { data } = await apolloClient.query({
    query: TAUTAN,
  });
  tautan.value = data.tautan;
};
onMounted(() => {
  const params = getUrlParams(window.location.href);
  getTautan();
  if (params && params.message) {
    $error($toast, params.message.join(''));
  }
});

const getDetail = ({ data, id }) => {
  return (data || []).filter((item) => Number(item.k_jenis_sosial) === Number(id))[0] || {};
};

const $toast = inject('moshaToast');
const $preferensi = inject('preferensi');
const open = ref(false);
const loading = ref(false);
const options = ref({});
const id = ref('');
const tipe = ref('');

const onClose = () => {
  open.value = false;
};
const onLink = (ID) => {
  open.value = true;
  id.value = ID;
  tipe.value = 'tautan';
  options.value = {
    title: 'Link Tautan Layanan',
    desc: 'Anda ingin mentautkan Layanan ini?',
    tipe: 'success',
    labelOk: 'Tautkan',
    labelNok: 'Batal',
  };
};
const onUnlink = (ID) => {
  open.value = true;
  id.value = ID;
  tipe.value = 'hapus';
  options.value = {
    title: 'Hapus Tautan Layanan',
    desc: 'Apakah Anda yakin untuk menghapus Tautan Layanan?',
    tipe: 'error',
    labelOk: 'Iya',
    labelNok: 'Batal',
  };
};
const onConfirm = () => {
  if (tipe.value === 'tautan') {
    onClose();
    window.location = (import.meta.env.VITE_PROXY_API || '') + '/sosial/redirect';
  } else hapusTautan();
};
const hapusTautan = () => {
  loading.value = true;
  apolloClient
    .mutate({
      mutation: HAPUS_TAUTAN,
      variables: {
        id: Number(id.value),
      },
      update: (store, { data: { deleteTautan } }) => {
        const data = store.readQuery({ query: TAUTAN });
        const mergeData = Object.assign({}, data, { tautan: deleteTautan });
        store.writeQuery({ query: TAUTAN, data: mergeData });
        tautan.value = deleteTautan;
      },
    })
    .then(() => {
      // loading
      loading.value = false;
      onClose();
      $success($toast, 'sukses');
    })
    .catch(({ message, graphQlErrors }) => {
      // loading
      loading.value = false;
      const error =
        message || (graphQlErrors && graphQlErrors[0] && graphQlErrors[0]['debugMessage']) || 'Aksi gagal dijalankan';
      $error($toast, error);
    });
};
</script>

<style scoped>
.success-chips {
  @apply justify-center
  items-center
  m-1
  mr-3
  font-medium
  py-1
  px-2
  rounded-full
  text-green-700
  bg-green-100;
}

.error-chips {
  @apply justify-center
  items-center
  m-1
  mr-3
  font-medium
  py-1
  px-2
  rounded-full
  text-red-700
  bg-red-100;
}
</style>
