import { CodeIcon, PuzzleIcon, UserCircleIcon, HomeIcon } from '@heroicons/vue/solid/esm';

const menus = [
  {
    title: 'Dashboard',
    desc: 'Halaman utama Anda',
    icon: HomeIcon,
    to: '/',
    hideOnHome: true,
  },
  {
    title: 'Data Personal',
    desc: 'Kelola Data dan Informasi personal SIMPKB Anda',
    icon: UserCircleIcon,
    to: '/profil',
  },
  { title: 'Integrasi Layanan', desc: 'Kelola Layanan yang Anda miliki', icon: CodeIcon, to: '/app' },
  {
    title: 'Data Lainnya',
    desc: 'Kelola Data dan Informasi Lainya',
    icon: PuzzleIcon,
    href: '#PREFERENSI_URL_SIMPKB#',
  },
];
export default menus;
