<template>
  <div class="container px-5 py-1 mx-auto flex sm:flex-nowrap flex-wrap">
    <div class="w-full md:py-1 mt-1 md:mt-0">
      <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Ubah Kata Sandi</h2>
      <p class="leading-relaxed mb-5 text-gray-600"> Kata sandi bersifat rahasia, jangan dipublikasikan </p>

      <div v-if="error" class="relative px-4 py-3 leading-normal text-red-700 bg-red-100 rounded-lg" role="alert">
        <span class="absolute inset-y-0 left-0 flex items-center ml-4">
          <XCircleIcon class="w-4 h-4 fill-current" />
        </span>
        <p class="ml-6">{{ error }}</p>
      </div>

      <div class="relative mb-4">
        <label for="password_lama" class="leading-7 text-sm text-gray-600">Kata sandi Lama</label>
        <input
          id="password_lama"
          v-model="form.password_lama"
          type="password"
          name="password_lama"
          class="
            w-full
            bg-white
            rounded
            border border-gray-300
            focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200
            text-base
            outline-none
            text-gray-700
            py-1
            px-3
            leading-8
            transition-colors
            duration-200
            ease-in-out
          "
        />
      </div>
      <div class="relative mb-4">
        <label for="password" class="leading-7 text-sm text-gray-600">Kata sandi Baru</label>
        <input
          id="password"
          v-model="form.password"
          type="password"
          name="password"
          class="
            w-full
            bg-white
            rounded
            border border-gray-300
            focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200
            text-base
            outline-none
            text-gray-700
            py-1
            px-3
            leading-8
            transition-colors
            duration-200
            ease-in-out
          "
        />
      </div>
      <div class="relative mb-4">
        <label for="password_confirmation" class="leading-7 text-sm text-gray-600">Konfirmasi Kata sandi Baru</label>
        <input
          id="password_confirmation"
          v-model="form.password_confirmation"
          type="password"
          name="password_confirmation"
          class="
            w-full
            bg-white
            rounded
            border border-gray-300
            focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200
            text-base
            outline-none
            text-gray-700
            py-1
            px-3
            leading-8
            transition-colors
            duration-200
            ease-in-out
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { XCircleIcon } from '@heroicons/vue/solid';
import { defineProps, toRefs } from 'vue';
const props = defineProps({
  form: Object,
  error: String,
});

const { form, error } = toRefs(props);
</script>

<style></style>
