<template>
  <div class="container px-5 py-1 mx-auto flex sm:flex-nowrap flex-wrap">
    <div class="w-full md:py-1 mt-1 md:mt-0">
      <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Ubah Profil</h2>
      <p class="leading-relaxed mb-5 text-gray-600">Sekumpulan Informasi Pribadi yang Anda miliki</p>

      <div v-if="error" class="relative px-4 py-3 leading-normal text-red-700 bg-red-100 rounded-lg" role="alert">
        <span class="absolute inset-y-0 left-0 flex items-center ml-4">
          <XCircleIcon class="w-4 h-4 fill-current" />
        </span>
        <p class="ml-6">{{ error }}</p>
      </div>
      <div class="relative mb-4">
        <label for="nama" class="leading-7 text-sm text-gray-600">Nama Lengkap</label>
        <input
          id="nama"
          v-model="params.nama"
          type="text"
          name="nama"
          class="
            w-full
            bg-white
            rounded
            border border-gray-300
            focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200
            text-base
            outline-none
            text-gray-700
            py-1
            px-3
            leading-8
            transition-colors
            duration-200
            ease-in-out
          "
        />
      </div>
      <div class="relative mb-4">
        <label for="kelamin" class="leading-7 text-sm text-gray-600">Jenis Kelamin</label>
        <div class="mt-2 text-gray-600">
          <label class="inline-flex items-center">
            <input v-model="params.kelamin" type="radio" class="form-radio" name="kelamin" value="L" />
            <span class="ml-2">Laki - laki</span>
          </label>
          <label class="inline-flex items-center ml-6">
            <input v-model="params.kelamin" type="radio" class="form-radio" name="kelamin" value="P" />
            <span class="ml-2">Perempuan</span>
          </label>
        </div>
      </div>
      <div class="relative flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label for="tmp_lahir" class="leading-7 text-sm text-gray-600">Tempat Lahir</label>
          <input
            id="tmp_lahir"
            v-model="params.tmp_lahir"
            type="text"
            name="tmp_lahir"
            class="
              w-full
              bg-white
              rounded
              border border-gray-300
              focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200
              text-base
              outline-none
              text-gray-700
              py-1
              px-3
              leading-8
              transition-colors
              duration-200
              ease-in-out
            "
          />
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label for="password_confirmation" class="leading-7 text-sm text-gray-600">Tanggal lahir</label>
          <DatePicker
            v-model="params.tgl_lahir"
            :masks="{
              input: 'DD MMMM YYYY',
            }"
            :model-config="{
              type: 'string',
              mask: 'YYYY-MM-DD',
            }"
          >
            <template #default="{ inputValue, togglePopover }">
              <input
                class="
                  w-full
                  bg-white
                  rounded
                  border border-gray-300
                  focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200
                  text-base
                  outline-none
                  text-gray-700
                  py-1
                  px-3
                  leading-8
                  transition-colors
                  duration-200
                  ease-in-out
                "
                :value="inputValue"
                @click="togglePopover()"
              />
            </template>
          </DatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XCircleIcon } from '@heroicons/vue/solid';
import { defineEmits, defineProps, ref, toRefs, watch } from 'vue';
import { Calendar, DatePicker } from 'v-calendar';

const props = defineProps({
  form: Object,
  error: String,
});

const { form, error } = toRefs(props);
const params = ref({ ...form.value });
const emit = defineEmits(['mutate']);
watch(
  () => params,
  (state) => {
    emit('mutate', state);
  },
  { deep: true }
);
</script>

<style></style>
