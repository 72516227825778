<template>
  <div class="container px-5 py-1 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-1 text-gray-900"> Data Personal </h1>
      <p class="leading-relaxed text-blue-600"> Kelola informasi pribadi akun Anda untuk SIMPKB </p>
    </div>

    <div class="lg:w-2/3 md:w-2/3 mx-auto pb-4">
      <div class="p-4 h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-sm">
        <h1 class="text-gray-500 text-xl title-font">Informasi Dasar</h1>
        <p class="leading-relaxed mb-4 text-gray-500 text-sm"> Sekumpulan Informasi Pribadi yang Anda miliki </p>
        <div class="flex flex-col md:flex-row border-gray-200 py-2 text-xs items-center relative">
          <span class="text-blue-600 font-bold uppercase">Foto Profil</span>
          <span class="ml-auto text-left text-gray-500">
            Gunakan foto profil resmi untuk memudahkan orang lain mengenal Anda
          </span>
          <div class="md:ml-auto rounded-full">
            <div class="relative">
              <img
                class="rounded-full h-10 w-10 object-cover"
                :src="(user && user.foto_url) || 'https://img.icons8.com/cotton/2x/gender-neutral-user--v2.png'"
                alt="avatar"
              />
              <div
                v-if="$preferensi.acl.upload_foto"
                id="edit-profpic"
                class="
                  cursor-pointer
                  inline-flex
                  items-center
                  justify-center
                  w-5
                  h-5
                  rounded-full
                  bg-blue-600
                  border border-white
                  absolute
                  inset-0
                  mb-0
                  mr-0
                  m-auto
                "
              >
                <svg
                  class="text-white w-3 h-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <ProfilPicture trigger="#edit-profpic" @upload="onUpload" />
            </div>
          </div>
        </div>
        <div
          v-for="(item, idx) in profil"
          :key="idx"
          class="flex flex-col md:flex-row border-t border-gray-200 text-xs py-2"
        >
          <span class="text-blue-600 font-bold uppercase">{{ item.label }}</span>
          <div class="md:ml-auto flex flex-row">
            <span class="flex-1 mr-2 text-left text-gray-500">{{ item.value }}</span>
            <div
              v-if="$preferensi.acl.edit_profil"
              class="
                flex-none
                inline-flex
                items-center
                justify-center
                w-5
                h-5
                rounded-full
                bg-blue-600
                border border-white
                ml-auto
              "
              @click="onEdit('profil')"
            >
              <PencilIcon class="text-white w-3 h-3" />
            </div>
            <div v-else class="relative flex flex-col items-center group">
              <div
                class="
                  flex-none
                  inline-flex
                  items-center
                  justify-center
                  w-5
                  h-5
                  rounded-full
                  bg-gray-300
                  border border-gray
                  ml-auto
                "
              >
                <LockClosedIcon class="w-3 h-3" />
              </div>
              <div class="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
                  Data dari Dapodik
                </span>
                <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:w-2/3 md:w-2/3 mx-auto pb-4">
      <div class="p-4 h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-sm">
        <h1 class="text-gray-500 text-xl title-font">Informasi Kontak</h1>
        <p class="leading-relaxed mb-4 text-gray-500 text-sm"> Sekumpulan Informasi Kontak valid yang Anda miliki </p>
        <div class="flex flex-col md:flex-row border-t border-gray-200 text-xs py-2">
          <span class="text-blue-600 font-bold uppercase">Alamat Surel</span>
          <div class="md:ml-auto flex flex-row">
            <span class="flex-1 mr-2 text-left text-gray-500">
              {{ (user && user.email) || '-' }}
            </span>
            <div class="relative flex flex-col items-center group">
              <div
                class="
                  flex-none
                  inline-flex
                  items-center
                  justify-center
                  w-5
                  h-5
                  rounded-full
                  bg-gray-300
                  border border-gray
                  ml-auto
                "
              >
                <LockClosedIcon class="w-3 h-3" />
              </div>
              <div class="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
                  Email dikunci
                </span>
                <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row border-t border-gray-200 text-xs py-2">
          <span class="text-blue-600 font-bold uppercase">No. Handphone</span>
          <div class="md:ml-auto flex flex-row">
            <span class="text-left text-gray-500 mr-2">
              {{ (user && user.no_hp) || '-' }}
            </span>
            <div
              v-if="$preferensi.acl.edit_kontak"
              class="
                inline-flex
                items-center
                justify-center
                w-5
                h-5
                rounded-full
                bg-blue-600
                border border-white
                ml-auto
              "
              @click="onEdit('kontak')"
            >
              <PencilIcon class="text-white w-3 h-3" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:w-2/3 md:w-2/3 mx-auto pb-4">
      <div class="p-4 h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-sm">
        <h1 class="text-gray-500 text-xl title-font">Kata Sandi</h1>
        <p class="leading-relaxed mb-4 text-gray-500 text-sm">Kata sandi Anda Bersifat rahasia</p>
        <div class="flex flex-col md:flex-row border-t border-gray-200 text-xs py-2">
          <span class="text-blue-600 font-bold uppercase">Kata Sandi</span>
          <div class="md:ml-auto flex flex-row">
            <span class="text-left text-gray-500 mr-2">
              <input class="w-10" type="password" disabled readonly :value="'rahasia'" />
            </span>
            <div
              v-if="$preferensi.acl.edit_password"
              class="
                cursor-pointer
                inline-flex
                items-center
                justify-center
                w-5
                h-5
                rounded-full
                bg-blue-600
                border border-white
                ml-auto
              "
              @click="onEdit('password')"
            >
              <PencilIcon class="text-white w-3 h-3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="open" :loading="loading" @close="onClose" @save="onSave">
    <component :is="component" :form="params" :error="errorMessage" @mutate="onMutate"></component>
  </Modal>
</template>

<script setup>
import { PencilIcon, LockClosedIcon } from '@heroicons/vue/solid';
import apolloClient from '/src/apollo';
import { USER, UPDATE_FOTO, UPDATE_PASSWORD, UPDATE_PROFIL, UPDATE_KONTAK } from '/src/graphql/user';
import { localDate } from '/src/utils/format';
import { onMounted, shallowRef, ref, computed, inject } from 'vue';
import { $success, $error, $validateEmail } from '/src/utils/utitity';
import Modal from '/src/components/Modal.vue';
import ProfilPicture from '/src/components/ProfilPicture.vue';
import FormProfil from '/src/components/formulir/Profil.vue';
import FormKontak from '/src/components/formulir/Kontak.vue';
import FormPass from '/src/components/formulir/Password.vue';
import { useStore } from '../store';

const $toast = inject('moshaToast');
const $preferensi = inject('preferensi');
const $state = useStore();

const user = ref({});
const getUser = async () => {
  const { data } = await apolloClient.query({
    query: USER,
  });
  user.value = data.user;
};
onMounted(getUser);

const profil = computed(() => {
  const M_KELAMIN = {
    L: 'Laki - laki',
    P: 'Perempuan',
  };
  return [
    {
      label: 'Nama Lengkap',
      value: (user.value && user.value.nama) || '-',
    },
    {
      label: 'Tempat, Tanggal Lahir',
      value: [(user.value && user.value.tmp_lahir) || '-', localDate((user.value && user.value.tgl_lahir) || '')].join(
        ', '
      ),
    },
    {
      label: 'Jenis Kelamin',
      value: M_KELAMIN[(user.value && user.value.kelamin) || ''] || '-',
    },
  ];
});

const onUpload = (data) => {
  loading.value = true;
  apolloClient
    .mutate({
      mutation: UPDATE_FOTO,
      variables: {
        file: data,
      },
      update: (store, { data: { updateFoto } }) => {
        $state.updateFoto(updateFoto && updateFoto.foto_url);
        const data = store.readQuery({ query: USER });
        const mergeDataUser = Object.assign({}, data.user, updateFoto);
        const mergeData = Object.assign({}, data, { user: mergeDataUser });
        store.writeQuery({ query: USER, data: mergeData });
        user.value = mergeDataUser;
      },
    })
    .then(() => {
      // loading
      loading.value = false;
      $success($toast, 'sukses');
    })
    .catch(({ message, graphQlErrors }) => {
      // loading
      loading.value = false;
      const error =
        message || (graphQlErrors && graphQlErrors[0] && graphQlErrors[0]['debugMessage']) || 'Aksi gagal dijalankan';
      $error($toast, error);
    });
};

const uploaded = () => {
  $success($toast, 'sukses');
};

// modal
const JENIS_COMPONENT = {
  profil: FormProfil,
  kontak: FormKontak,
  password: FormPass,
};
const component = shallowRef('');
const open = ref(false);
const errorMessage = ref('');
const loading = ref(false);
const params = ref({});
let tipe = '';
const onEdit = (jenis) => {
  tipe = jenis;
  component.value = JENIS_COMPONENT[jenis];
  errorMessage.value = '';
  open.value = true;

  if (jenis !== 'password') params.value = { ...user.value };
};
const onMutate = (data) => {
  params.value = data.value;
};
const onClose = () => {
  open.value = false;
};
const onSave = () => {
  switch (tipe) {
    case 'profil':
      saveProfil();
      break;
    case 'kontak':
      saveKontak();
      break;
    case 'password':
      savePassword();
      break;
  }
};

const savePassword = () => {
  // loading
  loading.value = true;
  // validate
  const { password_lama, password, password_confirmation } = params.value;
  if (!password_lama || !password || !password_confirmation || password !== password_confirmation) {
    let title = !password_lama
      ? 'Kata sandi Lama'
      : !password
      ? 'Kata sandi Baru'
      : !password_confirmation
      ? 'Konfirmasi Kata sandi Baru'
      : 'Konfirmasi Kata sandi Baru';

    errorMessage.value = password !== password_confirmation ? `${title} tidak sama` : `${title} wajib diisi`;
    // loading
    loading.value = false;
    return;
  }

  apolloClient
    .mutate({
      mutation: UPDATE_PASSWORD,
      variables: {
        password_lama,
        password,
        password_confirmation,
      },
    })
    .then(() => {
      // loading
      loading.value = false;
      onClose();
      $success($toast, 'sukses');
    })
    .catch(({ message, graphQlErrors }) => {
      // loading
      loading.value = false;
      const error =
        message || (graphQlErrors && graphQlErrors[0] && graphQlErrors[0]['debugMessage']) || 'Aksi gagal dijalankan';
      $error($toast, error);
    });
};
const saveProfil = () => {
  // loading
  loading.value = true;
  // validate
  const { nama, kelamin, tmp_lahir, tgl_lahir } = params.value;
  if (!nama || !kelamin || !tmp_lahir || !tgl_lahir) {
    let title = !nama ? 'Nama Lengkap' : !kelamin ? 'Kelamin' : !tmp_lahir ? 'Tempat Lahir' : 'Tanggal Lahir';

    errorMessage.value = `${title} wajib diisi`;
    // loading
    loading.value = false;
    return;
  }

  apolloClient
    .mutate({
      mutation: UPDATE_PROFIL,
      variables: {
        nama,
        kelamin,
        tmp_lahir,
        tgl_lahir,
      },
      update: (store, { data: { updateProfil } }) => {
        $state.updateNama(updateProfil && updateProfil.nama);
        const data = store.readQuery({ query: USER });
        const mergeDataUser = Object.assign({}, data.user, updateProfil);
        const mergeData = Object.assign({}, data, { user: mergeDataUser });
        store.writeQuery({ query: USER, data: mergeData });
        user.value = mergeDataUser;
      },
    })
    .then(() => {
      // loading
      loading.value = false;
      onClose();
      $success($toast, 'sukses');
    })
    .catch(({ message, graphQlErrors }) => {
      // loading
      loading.value = false;
      const error =
        message || (graphQlErrors && graphQlErrors[0] && graphQlErrors[0]['debugMessage']) || 'Aksi gagal dijalankan';
      $error($toast, error);
    });
};
const saveKontak = () => {
  // loading
  loading.value = true;
  // validate
  const { email, no_hp } = params.value;
  if (!email || (email && !$validateEmail(email)) || !no_hp) {
    let title = !no_hp ? 'No. Handphone' : 'Alamat Surel';
    errorMessage.value = email && !$validateEmail(email) ? `${title} tidak valid` : `${title} wajib diisi`;
    // loading
    loading.value = false;
    return;
  }

  apolloClient
    .mutate({
      mutation: UPDATE_KONTAK,
      variables: {
        email,
        no_hp,
      },
      update: (store, { data: { updateKontak } }) => {
        const data = store.readQuery({ query: USER });
        const mergeDataUser = Object.assign({}, data.user, updateKontak);
        const mergeData = Object.assign({}, data, { user: mergeDataUser });
        store.writeQuery({ query: USER, data: mergeData });
        user.value = mergeDataUser;
      },
    })
    .then(() => {
      // loading
      loading.value = false;
      onClose();
      $success($toast, 'sukses');
    })
    .catch(({ message, graphQlErrors }) => {
      // loading
      loading.value = false;
      const error =
        message || (graphQlErrors && graphQlErrors[0] && graphQlErrors[0]['debugMessage']) || 'Aksi gagal dijalankan';
      $error($toast, error);
    });
};
</script>

<style scoped>
.rotate-45 {
  --transform-rotate: 45deg;
  transform: rotate(45deg);
}

.group:hover .group-hover\:flex {
  display: flex;
}
</style>
