<template>
  <template v-if="fecthing">
    <div
      class="
        fixed
        top-0
        left-0
        right-0
        bottom-0
        w-full
        h-screen
        z-50
        overflow-hidden
        bg-bg-white
        opacity-75
        flex flex-col
        items-center
        justify-center
      "
    >
      <svg fill="none" class="w-12 h-12 animate-spin" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </svg>
      <h2 class="text-center text-xl font-semibold">Loading...</h2>
      <p class="w-1/3 text-center">Sedang memuat data preferensi Anda</p>
    </div>
  </template>
  <template v-else>
    <Toolbar :user="user" :layanan="layanan"></Toolbar>
    <div class="w-full max-w-8xl mx-auto">
      <div class="lg:flex">
        <Sidemenu />
        <div id="content-wrapper" class="min-w-0 w-full flex-auto lg:pl-64 lg:static lg:max-h-full lg:overflow-visible">
          <div class="w-full flex">
            <div class="min-w-0 flex-auto px-4 sm:px-6 xl:px-8 pt-10 pb-24 lg:pb-16">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import apolloClient from '/src/apollo';
import { PREFERENSI } from '/src/graphql/preferensi.js';
import { onMounted, provide, ref, computed } from 'vue';
import Toolbar from './components/app/Toolbar.vue';
import Sidemenu from './components/app/Sidemenu.vue';
import { initStore } from './store';

const fecthing = ref(true);
const user = ref({});
const layanan = ref({});
const preferensi = ref({});

const getPreferensi = async () => {
  const { data, loading } = await apolloClient.query({
    query: PREFERENSI,
  });
  fecthing.value = loading;
  user.value = data.user;
  layanan.value = data.layanan;
  preferensi.value = data.preferensi;
};

initStore();

onMounted(getPreferensi);

provide('preferensi', preferensi);
</script>
