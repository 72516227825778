import { ApolloClient, from, HttpLink } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';

const errorHandler = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    window.location = (import.meta.env.VITE_PROXY_API || '') + '/auth/login';
  }
});

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_API_URL,
  credentials: 'include',
});

const apolloClient = new ApolloClient({
  link: from([errorHandler, httpLink]),
  cache: new InMemoryCache(),
});

export default apolloClient;
