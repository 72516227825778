import { gql } from '@apollo/client/core';

const PREFERENSI = gql`
  query GetPreferensi {
    layanan {
      nama
      url
      peran
    }
    user {
      nama
      foto_url
    }
    preferensi {
      acl {
        upload_foto
        edit_profil
        edit_kontak
        edit_password
        edit_tautan
      }
      url_simpkb
    }
  }
`;

export { PREFERENSI };
