import { createApp, provide, h } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import apolloClient from './apollo';
import App from './App.vue';
import './assets/css/index.css';
import router from './routers';
import moshaToast from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
});
app.use(router);
app.use(moshaToast);
app.mount('#app');
