export const $success = ($toast, message) => {
  $toast(
    {
      title: message,
    },
    {
      hideProgressBar: true,
      showIcon: true,
      swipeClose: false,
      type: 'success',
      position: 'top-right',
    }
  );
};

export const $error = ($toast, message, desc) => {
  $toast(
    {
      title: message,
      description: desc,
    },
    {
      hideProgressBar: true,
      showIcon: true,
      swipeClose: false,
      type: 'danger',
      position: 'top-right',
      timeout: 20000,
    }
  );
};

export const $info = ($toast, message, desc) => {
  $toast(
    {
      title: message,
      description: desc,
    },
    {
      hideProgressBar: true,
      showIcon: true,
      swipeClose: false,
      type: 'info',
      position: 'top-right',
      toastBackgroundColor: '#93C5FD',
    }
  );
};

export const $validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
